.perks {
  text-align: center;
  margin-bottom: 100px;
  &__artifical-padding {
    height: 70px;
  }
  .slick__header {
    padding-top: 100px;
  }
  .perks-block {
    background: url('../images/pattern2.png') repeat;
    position: relative;
    transition: 0.4s ease;
    max-height: 350px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    &:hover {
      .perks-block--back {
        visibility: visible;
        opacity: 1;
      }
      .perks-block--front {
        visibility: hidden;
        opacity: 0;
      }
    }
    &--front {
      font-weight: 800;
      min-height: 350px;
      transition: visibility 0s, opacity 0.5s linear;
      .fa {
        color: $brand-primary;
        font-size: 94px;
        padding-top: 41px;
        @include breakpoint(lg) {
          padding-top: 100px;
        }
      }
      h2 {
        line-height: 32px;
        font-size: 22px;
        color: $brand-primary;
        font-weight: 800;
        padding-bottom: 15px;
        @include breakpoint(md) {
          line-height: 45px;
          font-size: 34px;
        }
      }
    }
    &--back {
      display: flex;
      flex-direction: column;
      justify-content: center;
      visibility: hidden;
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear;
      background: rgba($brand-primary, 0.6);
      color: white;
      padding: 0 50px;
      h2 {
        font-weight: 800;
        font-size: 34px;
        color: #FFFFFF;
        line-height: 45px;
        margin-top: 45px;
        margin-bottom: 15px;
      }
      p {
        font-size: 14px;
        color: #FFFFFF;
        line-height: 22px;
        margin-bottom: 40px;
      }
    }
  }
}