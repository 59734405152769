.delivery-order-list {
    &__mobile {
        position: fixed;
        text-align: center;
        bottom: 0;
        color: white;
        padding: 5px;
        background: $c-blue;
        left: 0;
        right: 0;
        z-index: 9999999;
        a{
            color: white;
        }
        @media (min-width: $screen-lg-min) {
            display: none;
        }
    }
    &__form {
        &--hide {
            @include fadeOutLeft();
        }
    }
    &__message {
        border: 1px solid $c-grey;
        text-align: center;
        background: url('../Images/pattern.png');
        margin-bottom: 100px;
        &-mini-header {
            padding: 40px 20px 10px;
            margin: 0;
        }
        &-content {
            padding: 10px 20px 40px;
            margin: 0;
        }
        &-popup {
            min-height: 100px;
            position: fixed;
            z-index: 100;
            top: 10%;
            box-shadow: 0 2px 40px 0 rgba(2, 12, 27, 0.7);
            @include fadeOutLeft();
            &--visible {
                @include fadeInLeft();
            }
        }

        .slick__lead {
            margin-bottom: 10px;
        }
        &-desc {
            font-family: $font-family-sans-serif;
            font-size: 18px;
            color: $c-grey;
            letter-spacing: 0.17px;
            line-height: 26px;
            margin-bottom: 20px;
            &-padding {
                padding-bottom: 70px;
                margin: 0;
            }
        }
        .btn {
            margin-bottom: 70px;
        }
    }
    &__wrapper {
        &-rel {
            position: relative;
            transition: 0.3s ease-out;
        }
    }
    &--bug-fix {
        height: 330px;
    }
    &__header {
        background-image: linear-gradient(131deg, #009BBA 0%, #23B9D7 100%);
        @include breakpoint(sm) {
            font-size: 97px;
        }
        a {
            color: white;
        }

        color: white;
        font-weight: 800;
        line-height: 90px;
        text-transform: lowercase;
        @media (min-width: $screen-xs-max) {
            padding: 60px 0;
            margin-top: 30px;
            //margin-bottom: 100px;
        }
    }
    &__row {
        display: none;
        &--open {
            display: block;
        }
    }
    &__container {
        background: url('../Images/pattern.png');
        .page-head__header {
            text-align: right;
        }
    }
    &__configurator {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin: 0;
            padding: 0;
        }
    }
    &__item {
        display: block;
        padding: 10px;
        background-color: $c-yellow;
        color: black;
        list-style: none;
        &--active {
            background-color: $c-yellow-dark;
            color: white;
        }
    }

    &__summary {
        padding: 100px 0;
    }
    .order {
        transition: 0.3s ease-in;
        background-size: cover;
        text-align: center;
        position: relative;
        margin-bottom: 90px;
        @media screen and (min-width: $screen-sm) {
            margin-bottom: 30px;
        }
        &:hover {
            .order__image {
                img {
                    box-shadow: 0 2px 40px 0 rgba(2, 12, 27, 0.38);
                }
            }
            .order__corner {
                opacity: 1;
            }
            .order__hover-layer {
                opacity: 1;
            }
        }
        &-process {
            textarea {
                resize: none;
            }
            &__list {
                li {
                    position: relative;
                }
            }
            &__item {
                color: $c-grey;
                padding: 10px 15px 10px 30px;
                display: block;
                transition: ease 0.5s;
                &--active {
                    background: $c-grey;
                    color: white;
                }
            }
            .check-btn {
                position: absolute;
                top: 13px;
                left: 0;
                &__small {
                    display: block;
                    border-radius: 100%;
                    z-index: 5;
                    transition: border .25s linear;
                    height: 13px;
                    width: 13px;
                    margin: auto;
                }
                &__large {
                    visibility: hidden;
                    opacity: 0;
                    transition: opacity 0.5s ease;
                    position: absolute;
                    border-radius: 100%;
                    content: '';
                    height: 21px;
                    width: 21px;
                    top: -4px;
                    left: -4px;
                }
                &--active {
                    .check-btn__small {

                    }
                    .check-btn__large {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
            .check-btn--active {
                .check-btn {
                    border: 1px solid transparent;
                }
            }

            .margin-bottom-30 {
                margin-bottom: 30px;
            }
            .ui-select-container {
                .ui-select-match {
                    .btn {
                        font-weight: normal;
                    }
                    & > span {
                        font-size: 16px;
                        background-color: white;
                        color: #555555;
                        border-radius: 4px;
                        border: 1px solid transparent;
                        padding: 6px 12px;
                    }
                    &-text {
                        line-height: 23px;
                    }
                }
                .form-control {
                    padding: 6px 12px 6px 12px !important;
                    height: 36px;
                    box-sizing: border-box;
                }
            }
        }
        &-list {
            margin-top: 29px;
            position: relative;
            padding-bottom: 30px;
            margin-bottom: 100px;
            background: url('../Images/pattern.png');
            @include breakpoint(md) {
                padding-bottom: 0;
                background: none;
            }
            &__buttons {
                .order__button-counter {
                    width: 100%;
                    &-plus, &-minus {
                        padding-left: 7px;
                        padding-top: 3px;
                    }
                    &-count {
                        padding: 7px;
                        @include breakpoint(lg) {
                            padding: 14px;
                        }
                    }
                }
            }
            &__wrapper {
                max-height: 200vh;
                transition: 0.5s;
                overflow: hidden;
                &-hidden {
                    max-height: 300px;
                }
            }
            &__bg-pattern {
                background: url('../Images/pattern.png');
            }
            &__item {
                margin: 0 15px 30px 15px;
                @include breakpoint(md) {
                    margin: 0 0 10px 0;
                }
            }
            &__line-bar {
                margin: 20px 16px 0 16px;
                border-bottom: 1px solid #979797;
            }
            &__header {
                font-family: $font-family-sans-serif;
                font-weight: 100;
                font-size: 40px;
                line-height: 56px;
                letter-spacing: 0.25px;
                color: $c-grey;
                text-align: right;
                margin: 25px 15px 50px 0;
                @include breakpoint(md) {
                    font-size: 34px;
                }
                @include breakpoint(lg) {
                    font-size: 40px;
                }
                @include breakpoint(md) {
                    margin: 25px 0 50px 0;
                }
            }
            &__name {
                font-family: $font-family-sans-serif;
                font-weight: 400;
                color: $c-grey;
                font-size: 18px;
                letter-spacing: 0.17px;
                line-height: 26px;
                margin-bottom: 4px;
            }
            &__icon {
                line-height: 26px;
                float: right;
            }
            &__summary-bar {
                position: relative;
                padding: 10px 15px 10px 0;
                font-family: $font-family-sans-serif;
                font-weight: 800;
                color: white;
                font-size: 18px;
                letter-spacing: 0.3px;
                line-height: 25px;
                text-align: right;
                @include breakpoint(md) {
                    padding: 10px 0 10px 0;
                }
            }
            &__sum {
                font-family: $font-family-sans-serif;
                font-weight: 400;
                color: #9B9B9B;
                padding-top: 15px;
            }
            &__total {
                padding-right: 15px;
                font-family: $font-family-sans-serif;
                font-weight: 800;
                font-size: 34px;
                line-height: 45px;
                span {
                    font-size: 20px;
                }
            }
        }
        &__hover-layer {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            transition: 0.3s ease-in;
        }
        &__item {
            background: url('../Images/pattern.png');
            position: relative;
        }
        &__corner {
            height: 135px;
            width: 135px;
            z-index: 30;
        }
        &__image {
            //margin-left: -15px;
            //margin-right: -15px;
            position: relative;
            z-index: 20;
            img {
                transition: 0.3s ease-in;
                width: 100%;
                height: auto;
                @include breakpoint(md) {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        &__corner {
            transition: opacity 0.3s ease-in;
            opacity: 0;
            position: absolute;
            right: -1px;
            top: 0;
        }
        &__name {
            position: relative;
            z-index: 100;
            font-size: 16px;
            font-weight: 800;
            padding: 0 5px;
            color: #4B4A45;
            letter-spacing: 0.5px;
            line-height: 22px;
            margin-right: 5px;
            margin-left: 5px;
            @media (min-width: $screen-xs-max) {
                margin-top: 50px;
                margin-bottom: 5px;
            }
        }
        &__price {
            position: relative;
            z-index: 100;
            font-size: 18px;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 26px;
            margin-bottom: 30px;
        }
        &__ingredients {
            font-family: "Poppins";
            font-weight: 400;
            font-size: 14px;
            color: #9B9B9B;
            line-height: 22px;
        }
        &__button {
            position: relative;
            z-index: 100;
            &-counter {
                background: url('../Images/pattern.png');
                float: left;
                display: inline-block;
                width: 75px;
                height: 52px;
                background: white;
                position: relative;
                overflow: hidden;
                &-plus {
                    height: 26px;
                    color: white;
                    padding: 3px;
                    transition: 0.3s;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 50%;
                    &:hover {
                        background: white;
                    }
                }
                &-minus {
                    color: white;
                    background: $c-grey;
                    transition: 0.3s;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    top: 50%;
                    padding: 2px;
                    &:hover {
                        background: white;
                        color: $c-grey;
                    }
                }
                &-small-wrapper {
                    display: inline-block;
                    height: 52px;
                    width: 26px;
                    float: right;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                &-count {
                    color: #23B9D7;
                    font-weight: 800;
                    font-size: 18px;
                    display: inline-block;
                    padding: 14px;
                    height: 100%;
                    border: none;
                    &:hover {
                        box-shadow: none !important;
                        outline: 0px !important;
                    }
                }
            }
        }
    }
}

.delivery-PDF {
    background: url('../Images/PDF_bg.png') center bottom;
    background-repeat: no-repeat;
    border: 1px solid #4B4A45;
    margin-top: 30px;
    margin-bottom: 30px;
    &__name {
        font-size: 18px;
        color: #4B4A45;
        letter-spacing: 0.17px;
        line-height: 26px;
        margin: 35px 0;
        text-align: center;
    }
    &__button {
        text-align: center;
        .btn {
            border: 1px solid #4B4A45;
            color: #4B4A45;
            margin-bottom: 55px;
            .fa {
                color: #4B4A45;
            }
            &:hover {
                border: 1px solid $brand-primary;
                color: $brand-primary;
                .fa {
                    color: $brand-primary;
                }
            }
        }
    }
}

.order-list {
    &__btn {
        padding: 20px 0 30px 0;
    }
}

.delivery-resume {
    padding: 0 50px;
    margin-top: 100px;
    margin-bottom: 100px;
    background: url('../Images/delivery-resume-bg.png');
    background-size: cover;
    &__header {
        margin: 92.5px 0;
        color: white;
    }
    &__button {
        margin: 75px 0;
        .btn {
            //background: $c-grey !important;
            //border: 1px solid $c-grey !important;
            //color: white !important;
            //&:hover {
            //  background: $brand-primary !important;
            //  border: 1px solid $brand-primary !important;
            //  color: $c-grey !important;
            //}
        }
    }
}
