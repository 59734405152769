.contact {
  .app-site-contact {
    margin: 0 -15px;
    @include breakpoint(sm) {
      margin: 0;
    }
  }
  padding: 50px 0;
  .neos-contentcollection {
    h1 {
      font-family: "Poppins";
      font-weight: 100;
      font-size: 50px;
      color: $c-grey;
      letter-spacing: 0.31px;
      line-height: 66px;
      margin-bottom: 50px;
      margin-top: 100px;
    }
    h2, h3 {
      font-family: "Poppins";
      font-weight: 800;
      color: $brand-primary;
      letter-spacing: 0.3px;
      line-height: 25px;
    }

    h2 {
      font-size: 21px;
    }
    h3 {
      font-size: 18px;
    }
    p {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 20px;
      color: $c-grey;
      letter-spacing: 0.01px;
      line-height: 27px;
      margin-bottom: 5px;
      margin-top: 0;
      a {
        font-size: 20px;
      }
    }
    .btn {
      margin-top: 50px;
    }
  }
  .devhero-contact-box {
    background: url('../Images/pattern2.png') repeat;
    margin-bottom: 45px;
    padding: 50px 0;
    &>.col-sm-6 {
      padding-left: 0;
      @include breakpoint(sm) {
        padding-left: 50px;
      }
    }
    h2, h3 {
      font-family: "Poppins";
      font-weight: 800;
      color: $brand-primary;
      letter-spacing: 0.3px;
      line-height: 25px;
    }
    h2 {
      font-size: 21px;
    }
    h3 {
      font-size: 18px;
    }
    p {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 18px;
      color: #4B4A45;
      letter-spacing: 0.17px;
      line-height: 26px;
      margin-bottom: 5px;
      margin-top: 0;
      a {
        //font-size: 20px;
      }
    }
  }
  .neos-nodetypes-twocolumn {

  }
}