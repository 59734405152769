.showcase-static {
  margin-bottom: 100px;
  &-svg {
    padding: 15px;
    @media screen and (min-width: $screen-md) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .showcase-static__content {
      text-align: center;
      @include breakpoint(sm) {
        text-align: left;
      }
      @media screen and (min-width: $screen-sm) {
        padding: 0 90px;
      }
      h3 {
        @media screen and (min-width: $screen-sm) {
          font-size: 50px;
        }
        @media screen and (min-width: $screen-lg) {
          margin-bottom: 50px;
        }
        font-weight: 100;
        color: #4B4A45;
        letter-spacing: 0.3px;
        line-height: 66px;
      }
      p {
        text-align: center;
        @media screen and (min-width: $screen-sm) {
          text-align: left;
        }
        margin-bottom: 50px;
      }
      .btn {
        margin-bottom: 0;
        @include breakpoint(sm) {
          margin-bottom: 50px;
        }
        @include breakpoint(md) {
          margin-bottom: 0;
        }
      }
    }
  }
  &__bg {
    background: url('../Images/pattern.png') repeat;
  }
  &-image {
    @media screen and (min-width: $screen-sm) {
      padding-right: 0;
    }
    img {
      width: 100%;
      height: auto;
      @include breakpoint(md) {
        width: auto;
      }
    }
  }
  &-content {
    padding-left: 25px;
  }
}

.showcase-fullscreen {
  margin-bottom: 100px;
  h2 {
    //@media screen and (min-width: $screen-sm) {
    //  font-size: 34px;
    //}
    color: $brand-secondary;
    line-height: 45px;
    margin-bottom: 35px;
  }
  p {
    font-weight: 500;
    font-size: 14px;
    color: $c-grey;
    line-height: 22px;
    margin-bottom: 50px;
    text-align: center;
    @media screen and (min-width: $screen-sm) {
      text-align: right;
    }
  }
  .slick-slide {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in, opacity 0.3s ease-in;
  }
  .slick-slide.slick-current.slick-active {
    visibility: visible;
    opacity: 1;
  }
  &__static {
    .slick-prev, .slick-next {
      top: 220px;
    }
  }
  &__slick {
    .slick-next, .slick-prev {
      z-index: 1;
      height: 100%;
      width: 200px;
      &:before {
        font-size: 125px;
      }
    }
    .slick-next {
      right: 0;
      transition: 0.2s ease;
      &:hover {
        background: rgba(75, 74, 69, 0.3);
      }
    }
    .slick-prev {
      left: 0;
    }
    .showcase-static-svg {
      background-position: 4% 0 !important;
      padding: 15px 45px;
    }
    .showcase-static-image {
      padding-left: 0;
    }
    .showcase-static-image {
      padding-left: 0;
    }
    .showcase-static__content {
      h3 {
        text-align: center;
        @media screen and (min-width: $screen-sm) {
          text-align: right;
        }
      }
      p {
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0.17px;
        line-height: 26px;
        text-align: center;
        @media screen and (min-width: $screen-sm) {
          text-align: right;
        }

      }
      .btn {
        @media screen and (min-width: $screen-sm) {
          float: right;
        }
      }
    }
  }
}

