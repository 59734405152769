.page {
  &__pattern{
    background: url("../Images/pattern.png");
  }
  &-head {
    &__home-header {
      //margin-top: 100px;
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 28px;
      line-height: 40px;
      letter-spacing: 0.24px;
      text-align: center;
      @media screen and (min-width: $screen-sm) {
        margin-bottom: 70px;
        font-size: 50px;
        letter-spacing: 0.3px;
        line-height: 66px;
        text-align: center;
      }
      color: $brand-secondary;
      font-weight: 100;
    }
    &__home-headerwithsub {
      margin-bottom: 0;
    }
    &__home-subtitle {
      font-size: 18px;
      color: $brand-primary;
      letter-spacing: 0.3px;
      line-height: 25px;
      text-align: center;
      font-weight: 800;
      margin-bottom: 50px;
    }
    &__content {
      min-height: 100px;
      padding: 30px;
      margin-right: 100px;
      background: white;
      float: right;
      width: 50%;
    }
  }
  /** DEFAULTS **/
  &--default {
    .foot__logo {
      svg {
        path {
          fill: #EBC165;
        }
      }
    }
    .left-border {
      @include breakpoint(sm) {
        border-left: 1px dotted #EBC165;
      }
    }
    .menu__hover {
      &:hover {
        background: #EBC165;
        color: white;
      }
    }
    .page__bg {
      background: #EBC165;
      &--hover {
        &:hover {
          background: #EBC165;
        }
      }
      &--gradient {
        background-image: linear-gradient(-87deg, rgba(235, 193, 101, 0.00) 2%, #EBC165 96%);
      }
      &--fullgradient {
        background-image: linear-gradient(131deg, rgba(235, 193, 101, 1.00) 2%, #EBC165 96%);
      }
      &--md {
        background: #EBC165;
        @include breakpoint(md) {
          background: none;
        }
      }
    }
    .page__text {
      &:not(.btn), &:not(.nav-category__link) {
        color: #EBC165;
      }
      &--hover {
        &:hover {
          color: #EBC165;
        }
      }
    }
    .lead__rgba {
      background: rgba(235, 193, 101, 0.4);
      @include breakpoint(sm) {
        background: rgba(247, 230, 193, 1);
      }
    }
    .page__rgba {
      &--dark {
        background: rgba(235, 193, 101, 0.9);
      }
      &--light {
        background: rgba(235, 193, 101, 0.4);
      }
    }
    .page__border {
      &--full {
        border: 1px solid #EBC165;
      }
      &--bottom {
        border-bottom: 1px solid #EBC165;
      }
    }
    .page__svg {
      &--topleft {
        &--sm {
          @media screen and (min-width: $screen-md) {
            background: url('../Images/svgs/orange/corner_left_top.svg') no-repeat top left;
          }
        }
        &--md {
          @media screen and (max-width: $screen-md - 1){
            background: url('../Images/svgs/orange/corner_left_top.svg') no-repeat top left;
          }
        }
        background: url('../Images/svgs/orange/corner_left_top.svg') no-repeat top left;
      }
      &--topright {
        &--sm {
          @media screen and (min-width: $screen-md) {
            background: url('../Images/svgs/orange/corner_right_top.svg') no-repeat top right;
          }
        }
        &--md {
          @media screen and (max-width: $screen-md - 1) {
            background: url('../Images/svgs/orange/corner_right_top.svg') no-repeat top right;
          }
        }
        background: url('../Images/svgs/orange/corner_right_top.svg') no-repeat top right;
      }
    }
    .slick-dots .slick-active button {
      background: #EBC165;
    }
    .slick-prev:before, .slick-next:before {
      color: #EBC165;
    }
    .slick-prev:before, .slick-next:before {
      color: #EBC165;
    }
    .btn.btn-secondary {
      &:hover {
        color: #EBC165 !important;
        border: 1px solid #EBC165;
      }
    }
    .btn-primary {
      background: #EBC165;
      border: 1px solid #EBC165;
      &:hover {
        background: white;
        border: 1px solid $c-grey;
      }
    }
    .btn-third {
      border: 1px solid $c-grey;
      background: transparent;
      color: $c-grey;
      &:hover {
        color: #EBC165 !important;
        background: $c-grey;
        border: 1px solid $c-grey;
      }
    }
    .btn-fourth {
      width: 100%;
      border: 1px solid #EBC165;
      background: transparent;
      color: #EBC165 !important;
      &:hover {
        background: #EBC165;
        color: $c-grey !important;
      }
    }
    .lead-block__info h3 {
      color: #EBC165;
    }
    .check-btn--active {
      .check-btn__small {
        background: #EBC165;
      }
    }
    .check-btn__small {
      border: 1px solid #EBC165;
    }
    .check-btn__large {
      border: 1px solid #EBC165;
    }
    .neos-nodetypes-text {
      h3 {
        @extend .page__text;
      }
    }
  }

  /** COLOURS **/
  &--pink {
    .foot__logo {
      svg {
        path {
          fill: $c-pink;
        }
      }
    }
    .left-border {
      @include breakpoint(sm) {
        border-left: 1px dotted $c-pink;
      }
    }
    .menu__hover {
      &:hover {
        background: $c-pink;
        color: white;
      }
    }
    .lead__rgba {
      background: rgba(220, 118, 135, 0.4);
      @include breakpoint(sm) {
        background: rgba(239,198,205,1);
      }
    }
    .page__bg {
      background: $c-pink;
      &--hover {
        &:hover {
          background: $c-pink;
        }
      }
      &--gradient {
        background-image: linear-gradient(-87deg, rgba(235, 193, 101, 0) 2%, #DC7687 96%);
      }
      &--fullgradient {
        background-image: linear-gradient(131deg, #DC7687  2%, #DC7687 96%);
      }
      &--md {
        background: $c-pink;
        @include breakpoint(md) {
          background: none;
        }
      }
    }

    .page__text {
      color: $c-pink;
      &:not(.btn), &:not(.nav-category__link) {
        color: $c-pink;
      }
      &--hover {
        &:hover {
          color: $c-pink;
        }
      }
      &--strong {
        strong {
          color: $c-pink;
        }
      }
      &--btn {
        color: $c-pink !important;
        &:hover {
          color: $c-grey !important;
        }
      }
    }
    .page__rgba {
      &--dark {
        background: rgba(220, 118, 135, 0.9);
      }
      &--light {
        background: rgba(220, 118, 135, 0.4);
      }
    }
    .page__border {
      &--full {
        border: 1px solid $c-pink;
      }
      &--bottom {
        border-bottom: 1px solid $c-pink;
      }
    }
    .page__svg {
      &--topleft {
        &--sm {
          @media screen and (min-width: $screen-md) {
            background: url('../Images/svgs/pink/corner_left_top.svg') no-repeat top left;
            margin-left: -1px;
          }
        }
        &--md {
          @media screen and (max-width: $screen-md - 1) {
            background: url('../Images/svgs/pink/corner_left_top.svg') no-repeat top left;
          }
        }
        background: url('../Images/svgs/pink/corner_left_top.svg') no-repeat top left;
      }
      &--topright {
        &--sm {
          @media screen and (min-width: $screen-md) {
            background: url('../Images/svgs/pink/corner_right_top.svg') no-repeat top right;
          }
        }
        &--md {
          @media screen and (max-width: $screen-md - 1) {
            background: url('../Images/svgs/pink/corner_right_top.svg') no-repeat top right;
          }
        }
        background: url('../Images/svgs/pink/corner_right_top.svg') no-repeat top right;
      }
    }
    .slick-dots .slick-active button {
      background: $c-pink;
    }
    .slick-prev:before, .slick-next:before {
      color: $c-pink;
    }
    .slick-prev:before, .slick-next:before {
      color: $c-pink;
    }
    .btn-third {
      border: 1px solid $c-grey;
      background: transparent;
      color: $c-grey;
      &:hover {
        color: $c-pink !important;
        background: $c-grey;
        border: 1px solid $c-grey;
      }
    }
    .btn-fourth {
      width: 100%;
      border: 1px solid $c-pink;
      background: transparent;
      color: $c-pink !important;
      &:hover {
        background: $c-pink;
        color: $c-grey !important;
      }
    }
    .btn.btn-secondary {
      &:hover {
        color: $c-pink !important;
        border: 1px solid $c-pink;
      }
    }
    .btn-primary {
      background: $c-pink;
      border: 1px solid $c-pink;
      &:hover {
        background: white;
        border: 1px solid $c-grey;
      }
    }
    .lead-block__info h3 {
      color: $c-pink;
    }
    .check-btn--active {
      .check-btn__small {
        background: $c-pink;
      }
    }
    .check-btn__small {
      border: 1px solid $c-pink;
    }
    .check-btn__large {
      border: 1px solid $c-pink;
    }
    .neos-nodetypes-text {
      h3 {
        @extend .page__text;
      }
    }
  }
  &--blue {
    .lead__rgba {
      background: rgba(165,225,237,1);
    }
    .foot__logo {
      svg {
        path {
          fill: #23B9D7;
        }
      }
    }
    .left-border {
      @include breakpoint(sm) {
        border-left: 1px dotted #23B9D7;
      }
    }
    .menu__hover {
      &:hover {
        background: #23B9D7;
        color: white;
      }
    }
    .page__bg {
      background: #23B9D7;
      &:hover {
        background: #23B9D7;
      }
      &--hover {
        &:hover {
          background: #23B9D7;
        }
      }
      &--gradient {
        background-image: linear-gradient(-87deg, rgba(235, 193, 101, 0) 2%, #23B9D7 96%);
      }
      &--fullgradient {
        background-image: linear-gradient(131deg, #009BBA 0%, #23B9D7 100%);
      }
      &--md {
        background: #23B9D7;
        @include breakpoint(md) {
          background: none;
        }
      }
    }
    .page__text {
      color: #23B9D7;
      &:not(.btn), &:not(.nav-category__link) {
        color: #23B9D7;
      }
      &--hover {
        &:hover {
          color: #23B9D7;
        }
      }
      &--strong {
        strong {
          color: #23B9D7;
        }
      }
      &--btn {
        color: #23B9D7 !important;
        &:hover {
          color: $c-grey !important;
        }
      }
    }
    .page__rgba {
      &--dark {
        background: rgba(35, 185, 215, 0.9);
      }
      &--light {
        background: rgba(35, 185, 215, 0.4);
      }
    }
    .page__border {
      &--full {
        border: 1px solid #23B9D7;
      }
      &--bottom {
        border-bottom: 1px solid #23B9D7;
      }
    }
    .page__svg {
      &--topleft {
        &--sm {
          @media screen and (min-width: $screen-md) {
            background: url('../Images/svgs/blue/corner_left_top.svg') no-repeat top left;
          }
        }
        &--md {
          @media screen and (max-width: $screen-md - 1){
            background: url('../Images/svgs/blue/corner_left_top.svg') no-repeat top left;
          }
        }
        background: url('../Images/svgs/blue/corner_left_top.svg') no-repeat top left;
      }
      &--topright {
        &--sm {
          @media screen and (min-width: $screen-md) {
            background: url('../Images/svgs/blue/corner_right_top.svg') no-repeat top right;
          }
        }
        &--md {
          @media screen and (max-width: $screen-md - 1) {
            background: url('../Images/svgs/blue/corner_right_top.svg') no-repeat top right;
          }
        }
        background: url('../Images/svgs/blue/corner_right_top.svg') no-repeat top right;
      }
    }
    .slick-dots .slick-active button {
      background: #23B9D7;
    }
    .slick-prev:before, .slick-next:before {
      color: #23B9D7;
    }
    .slick-prev:before, .slick-next:before {
      color: #23B9D7;
    }
    .btn.btn-secondary {
      &:hover {
        color: #23B9D7 !important;
        border: 1px solid #23B9D7;
      }
    }
    .btn-third {
      border: 1px solid $c-grey;
      background: transparent;
      color: $c-grey;
      &:hover {
        color: #23B9D7 !important;
        background: $c-grey;
        border: 1px solid $c-grey;
      }
    }
    .btn-fourth {
      width: 100%;
      border: 1px solid #23B9D7;
      background: transparent;
      color: #23B9D7 !important;
      &:hover {
        background: #23B9D7;
        color: $c-grey !important;
      }
    }
    .btn-primary {
      background: #23B9D7;
      border: 1px solid #23B9D7;
      &:hover {
        background: white;
        border: 1px solid $c-grey;
      }
    }
    .lead-block__info h3 {
      color: #23B9D7;
    }
    .check-btn--active {
      .check-btn__small {
        background: #23B9D7;
      }
    }
    .check-btn__small {
      border: 1px solid #23B9D7;
    }
    .check-btn__large {
      border: 1px solid #23B9D7;
    }
    .neos-nodetypes-text {
      h3 {
        @extend .page__text;
      }
    }
  }

  &--green {
    .foot__logo {
      svg {
        path {
          fill: $c-green;
        }
      }
    }
    .left-border {
      @include breakpoint(sm) {
        border-left: 1px dotted $c-green;
      }
    }
    .lead__rgba {
      background: rgba(184, 233, 134, 0.4);
      @include breakpoint(sm) {
        background: rgb(216, 235, 196);
      }
    }
    .menu__hover {
      &:hover {
        background: $c-green;
        color: white;
      }
    }
    .page__bg {
      background: $c-green;
      &--hover {
        &:hover {
          background: $c-green;
        }
      }
      &--gradient {
        background-image: linear-gradient(-87deg, rgba(235, 193, 101, 0) 2%, $c-green 96%);
      }
      &--fullgradient {
        background-image: linear-gradient(131deg, #B8E986  2%, #B8E986 96%);
      }
      &--md {
        background: $c-green;
        @include breakpoint(md) {
          background: none;
        }
      }
    }
    .page__text {
      color: $c-green;
      &:not(.btn), &:not(.nav-category__link) {
        color: $c-green;
      }
      &--hover {
        &:hover {
          color: $c-green;
        }
      }
      &--strong {
        strong {
          color: $c-green
        }
      }
      &--btn {
        color: $c-green !important;
        &:hover {
          color: $c-grey !important;
        }
      }
    }
    .page__rgba {
      &--dark {
        background: rgba(184, 233, 134, 0.9);
      }
      &--light {
        background: rgba(184, 233, 134, 0.4);
      }
    }
    .page__border {
      &--full {
        border: 1px solid $c-green;
      }
      &--bottom {
        border-bottom: 1px solid $c-green
      }
    }
    .page__svg {
      &--topleft {
        &--sm {
          @media screen and (min-width: $screen-md) {
            background: url('../Images/svgs/green/corner_left_top.svg') no-repeat top left;
          }
        }
        &--md {
          @media screen and (max-width: $screen-md - 1) {
            background: url('../Images/svgs/green/corner_left_top.svg') no-repeat top left;
          }
        }
        background: url('../Images/svgs/green/corner_left_top.svg') no-repeat top left;
      }
      &--topright {
        &--sm {
          @media screen and (min-width: $screen-md) {
            background: url('../Images/svgs/green/corner_right_top.svg') no-repeat top right;
          }
        }
        &--md {
          @media screen and (max-width: $screen-md - 1) {
            background: url('../Images/svgs/green/corner_right_top.svg') no-repeat top right;
          }
        }
        background: url('../Images/svgs/green/corner_right_top.svg') no-repeat top right;
      }
    }
    .slick-dots .slick-active button {
      background: $c-green;
    }
    .slick-prev:before, .slick-next:before {
      color: $c-green;
    }
    .slick-prev:before, .slick-next:before {
      color: $c-green;
    }
    .btn.btn-secondary {
      &:hover {
        color: $c-green !important;
        border: 1px solid $c-green;
      }
    }
    .btn-third {
      border: 1px solid $c-grey;
      background: transparent;
      color: $c-grey;
      &:hover {
        color: $c-green !important;
        background: $c-grey;
        border: 1px solid $c-grey;
      }
    }
    .btn-fourth {
      width: 100%;
      border: 1px solid $c-green;
      background: transparent;
      color: $c-green !important;
      &:hover {
        background: $c-green;
        color: $c-grey !important;
      }
    }
    .btn-primary {
      background: $c-green;
      border: 1px solid $c-green;
      &:hover {
        background: white;
        border: 1px solid $c-grey;
      }
    }
    .lead-block__info h3 {
      color: $c-green;
    }
    .check-btn--active {
      .check-btn__small {
        background: $c-green;
      }
    }
    .check-btn__small {
      border: 1px solid $c-green;
    }
    .check-btn__large {
      border: 1px solid $c-green;
    }
    .neos-nodetypes-text {
      h3 {
        @extend .page__text;
      }
    }
  }

  &--orange {
    .foot__logo {
      svg {
        path {
          fill: $c-orange;
        }
      }
    }
    .left-border {
      @include breakpoint(sm) {
        border-left: 1px dotted $c-orange;
      }
    }
    .menu__hover {
      &:hover {
        background: $c-orange;
        color: white;
      }
    }
    .page__bg {
      background: $c-orange;
      &--hover {
        &:hover {
          background: $c-orange;
        }
      }
      &--gradient {
        background-image: linear-gradient(-87deg, rgba(235, 193, 101, 0) 2%, $c-orange 96%);
      }
      &--fullgradient {
        background-image: linear-gradient(131deg, #E78110  2%, #E78110 96%);
      }
      &--md {
        background: $c-orange;
        @include breakpoint(md) {
          background: none;
        }
      }
    }
    .page__text {
      color: $c-orange;
      &:not(.btn), &:not(.nav-category__link) {
        color: $c-orange;
      }
      &--hover {
        &:hover {
          color: $c-orange;
        }
      }
      &--strong {
        strong {
          color: $c-orange;
        }
      }
      &--btn {
        color: $c-orange !important;
        &:hover {
          color: $c-grey !important;
        }
      }
    }
    .page__rgba {
      &--dark {
        background: rgba(231, 129, 16, 0.9);
      }
      &--light {
        background: rgba(231, 129, 16, 0.4);
      }
    }
    &__border {
      &--full {
        border: 1px solid $c-orange;
      }
      &--bottom {
        border-bottom: 1px solid $c-orange;
      }
    }
    .page__svg {
      &--topleft {
        &--sm {
          @media screen and (min-width: $screen-md) {
            background: url('../Images/svgs/orange-d/corner_left_top.svg') no-repeat top left;
          }
        }
        &--md {
          @media screen and (max-width: $screen-md - 1) {
            background: url('../Images/svgs/orange-d/corner_left_top.svg') no-repeat top right;
          }
        }
        background: url('../Images/svgs/orange-d/corner_left_top.svg') no-repeat top right;
      }
      &--topright {
        &--sm {
          @media screen and (min-width: $screen-md) {
            background: url('../Images/svgs/orange-d/corner_right_top.svg') no-repeat top right;
          }
        }
        &--md {
          @media screen and (max-width: $screen-md - 1) {
            background: url('../Images/svgs/orange-d/corner_right_top.svg') no-repeat top right;
          }
        }
        background: url('../Images/svgs/orange-d/corner_right_top.svg') no-repeat top right;
      }
    }
    .slick-dots .slick-active button {
      background: $c-orange;
    }
    .slick-prev:before, .slick-next:before {
      color: $c-orange;
    }
    .slick-prev:before, .slick-next:before {
      color: $c-orange;
    }
    .btn-third {
      border: 1px solid $c-grey;
      background: transparent;
      color: $c-grey;
      &:hover {
        color: $c-orange !important;
        background: $c-grey;
        border: 1px solid $c-grey;
      }
    }
    .btn-fourth {
      width: 100%;
      border: 1px solid $c-orange;
      background: transparent;
      color: $c-orange !important;
      &:hover {
        background: $c-orange;
        color: $c-grey !important;
      }
    }
    .btn.btn-secondary {
      &:hover {
        color: $c-orange !important;
        border: 1px solid $c-orange;
       }
    }
    .btn-primary {
      background: $c-orange;
      border: 1px solid $c-orange;
      &:hover {
        background: white;
        border: 1px solid $c-grey;
      }
    }
    .lead-block__info h3 {
      color: $c-orange;
    }
    .check-btn--active {
      .check-btn__small {
        background: $c-orange;
      }
    }
    .check-btn__small {
      border: 1px solid $c-orange;
    }
    .check-btn__large {
      border: 1px solid $c-orange;
    }
  }
  .neos-nodetypes-text {
    h3 {
      @extend .page__text;
    }
  }
}
