.foot {
  padding: 75px 20px;
  //color: $brand-primary;
  background: $brand-secondary;
  h1, h2, h3, h4 {
    margin-bottom: 20px;
  }
  a:not(.btn) {
    &:hover {
      //color: $brand-primary;
      text-decoration: underline;
    }
  }
  &__logo {
    img {
      margin: 0 auto;
      display: block;
    }
  }
  &__sections {
    .btn {
      margin-top: 30px;
    }
  }
  &__sitemap {
    margin-top: 25px;
    @media screen and (min-width: $screen-sm) {
      margin-top: 0;
      margin-bottom: 0;
      //border-left: 1px dotted $brand-primary;

    }
    h3 {
      text-align: center;
      @media screen and (min-width: $screen-sm) {
        text-align: left;
        margin-top: 0;
      }
      margin-top: 50px;
      font-size: 18px;
      //color: $brand-primary;
      letter-spacing: 0.3px;
      line-height: 25px;
      margin-bottom: 10px;
    }
    ul {
      padding-left: 0;
      list-style: none;
      text-align: center;
      @media screen and (min-width: $screen-sm) {
        text-align: left;
      }
      li {
        margin-bottom: 5px;
        color: white;
        a {
          font-weight: 600;
          color: white;
        }
      }
    }
  }
  &__social {
    margin-bottom: 25px;
    text-align: center;
    @media screen and (min-width: $screen-sm) {
      margin-bottom: 0;
      //border-left: 1px dotted $brand-primary;
    }
    img {
      margin-bottom: 20px;
      @media screen and (min-width: $screen-sm) {
        margin-bottom: 90px;
      }
    }
    .fa {
      font-size: 32px;
      transition: 0.4s ease;
      &:hover {
        color: white;
      }
    }
    li {
      display: inline-block;
      margin-right: 25px;

      @media screen and (min-width: $screen-sm) {
        margin-right: 0;
        display: block;
      }
      margin-bottom: 35px;
      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
  &__contact {
    margin-bottom: 25px;
    text-align: center;
    @media screen and (min-width: $screen-sm) {
      //border-left: 1px dotted $brand-primary;
      margin-bottom: 0;
      text-align: left;
    }
    h3 {
      font-size: 18px;
      //color: $brand-primary;
      letter-spacing: 0.3px;
      line-height: 25px;
      margin-top: 50px;
      margin-bottom: 10px;
      text-align: center;
      @media screen and (min-width: $screen-sm) {
        margin-top: 0;
        text-align: left;
      }
    }
    li {
      font-size: 15px;
      color: white;
      a {
        color: white;
      }
    }
    .btn {
      color: $c-grey !important;
    }
  }
}

.floating-contact {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 999999999999999999999999999999999999;
  background: white;
  padding: 5px;
  &__text {
    margin-top: 14px;
  }

  &__block {
    text-align: center;
    padding: 10px 5px;
    max-width: 145px;
    border: 1px solid $c-blue-dark;
    a {
      font-size: 12px;
      font-weight: 600;
      color: $c-blue;
      &:hover {
        color: $c-red;
      }
    }
    &--1 {
      margin-right: 5px;
    }
    &--2 {
      margin-left: 5px;
    }
  }
  .fa {
    color: $c-red;
  }
  &--tablet {
    display: none;
    font-weight: 600;
    font-size: 20px;
    color: $c-blue;
    letter-spacing: 0;
    text-align: left;
    .fa {
      font-size: 20px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      display: inline-block !important;
    }
  }
}

.credits {
  font-size: 14px;
  color: white;
  //background: $brand-primary;
  //border-top: 1px solid $brand-primary;
  letter-spacing: 0.14px;
  line-height: 20px;
  .row {
    padding: 15px;
    .col-xs-12 {
      text-align: center;
      @media screen and (min-width: $screen-sm-min) {
        text-align: right;
      }
    }
  }
  &__brand {
    img {
      padding-left: 5px;
      display: inline-block;
      height: 15px;
      width: auto;
    }
  }
}