$screen-lg: 1300px;
$container-large-desktop: 1270px;
$font-size-h1: 32px;
$font-size-h2: 27px;
$font-size-h3: 21px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 13px;
$font-size-base: 16px;

// Variables go here
$c-green-dark: #5D7D00;

$c-yellow: #ffcc07;
$c-yellow-dark: #C77D00;

$c-pink-dark: #ff00aa;

$c-grey: #4B4A45;
$c-grey-light: #D8D8D8;

$c-red: #cd1719;
$c-red-dark:#a2141d;
$c-blue-light: #53ccf9;
$c-blue-dark: #150a2d;


$c-blue: #23B9D7;
$c-green: #B8E986;
$c-pink: #DC7687;
$c-orange: #E78110;

$brand-primary: #EBC165;
$brand-secondary: #4B4A45;

$font-family-sans-serif: 'Poppins', "Helvetica Neue", Helvetica, Arial, sans-serif !default;

$screen-xlg: 1441px;
