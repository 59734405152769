.pagination {
 li {
   a {
     color: $brand-secondary;
     letter-spacing: 1px;
     line-height: 22px;
     font-size: 12px;
     padding: 9px 16px 8px;
     border: 1px solid $brand-secondary;
     width: 40px;
     height: 40px;
     margin: 0 4px;
     &:hover {
       border: 1px solid $brand-primary;
       background: $brand-primary;
       color: $brand-secondary;
     }
   }
   .active {
     color: $brand-primary;
   }
   &.current{
     a{
       background: $brand-primary;
       color: white;
     }
   }
 }
  &>li:first-child>a {
    border-radius: 0 !important;
  }
}