.services {
  margin-bottom: 100px;
  &__background {
    background: url('../Images/pattern.png');
  }
  .services-block {
    @include breakpoint(sm) {
      margin-bottom: 0;
      min-height: 525px;
      max-height: 600px;
    }
    max-height: 250px;
    margin-bottom: 30px;
    background: url('../Images/pattern2.png') repeat;
    width: 100%;
    position: relative;
    transition: 0.4s ease;
    overflow: hidden;
    @media screen and (min-width: $screen-sm) {
      width: 50%;
      float: left;
    }
    &__arrow {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear;
    }
    &--firstrow {
      .services-block__arrow {
        background: url('../Images/svgs/orange/corner_left_top.svg') no-repeat top left;
      }
    }
    &--secondrow {
      .services-block__arrow {
        background: url('../Images/svgs/orange/corner_left_bottom.svg') no-repeat bottom left;
      }
    }
    &__button {
      text-align: left;
    }
    &__front {
      background: url('../Images/block2.png');
      background-size: cover;
      height: 100%;
      .services-block__title {
        font-weight: 800;
        @media screen and (min-width: $screen-md) {
          font-size: 60px;
          line-height: 60px;
        }
        @media screen and (min-width: $screen-lg) {
          font-size: 86px;
          line-height: 86px;
        }
        left: auto;
        text-align: right;
        /* Obsługa i zaopatrzen: */
        line-height: auto;
        font-size: 30px;
        color: white;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    &__back {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear;
      padding: 15px 35px;
      @include breakpoint(sm) {
        max-height: 600px;
      }
      height: 250px;
      @media screen and (max-width: $screen-sm) {
        left: 0;
        top: 0;
        right: 0;
      }
      @media screen and (min-width: $screen-sm) {
        padding: 70px;
      }
      .btn {
        @media screen and (min-width: $screen-sm) {
          padding: 15px 40px;
        }
      }
      h3 {
        font-family: $font-family-sans-serif;
        font-weight: 800;
        text-decoration: underline;
        font-size: 34px;
        color: #D4A53E;
        line-height: 45px;
        margin-top: 25px;
        margin-bottom: 0;
        text-align: left;
      }
      p {
        @media screen and (min-width: $screen-sm) {
          margin-top: 30px;
          margin-bottom: 50px;
        }
        text-align: left;
        margin-top: 10px;
        margin-bottom: 20px;
        font-family: $font-family-sans-serif;
        font-weight: 400;
        color: #4B4A45;
        letter-spacing: 0.17px;
        line-height: 26px;

      }
    }
    &:hover {
      .services-block__arrow {
        opacity: 1;
        visibility: visible;
      }
      .services-block__back {
        @include fadeIn(
                $duration: 0.4s
        );
      }
      .services-block__front {
        @include fadeOut(
                $duration: 0.4s
        );
      }
    }
  }
}

.services-block.services-block--firstrow.services-block--last {
  .services-block__arrow {
    background: url('../Images/svgs/orange/corner_right_top.svg') no-repeat top right;
  }
}

.services-block.services-block--secondrow.services-block--last {
  .services-block__arrow {
    background: url('../Images/svgs/orange/corner_right_bottom.svg') no-repeat bottom right;
  }
}