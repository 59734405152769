
.breadcrumbs {
  margin-left: 0;
  background: transparent;
  @media screen and (min-width: $screen-sm-min) {
    padding: 35px 0;
  }
  li {
    &:first-child {
      margin-left: 0;
    }
    display: inline;
    & + li:before {
      content: "\f0da";
      font: normal normal normal 14px/1 FontAwesome;
      text-decoration: none;
      padding: 0 5px;
      color: $brand-secondary;
    }

    &.active {
      font-weight: 800;
      color: $brand-secondary;
    }
    a {
      color: $c-grey;
    }
  }
  @media screen and (min-width: $screen-md-min) {
    margin-top: 0;
  }

}