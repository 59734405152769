@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  font-style: normal;
  src: url('../Fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  font-style: normal;
  src: url('../Fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: url('../Fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: url('../Fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  src: url('../Fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: normal;
  src: url('../Fonts/Poppins-Bold.ttf') format('truetype');
}