﻿$hamburger-menu-size: 60px;
$hamburger-menu-border-radius: .12em;
$hamburger-menu-bg-color: rgba($c-grey, .75);
$hamburger-menu-line-height: 5px;
$hover-line-height: $hamburger-menu-line-height;
$menu-text-color: darken($brand-primary, 0.1);
$content-bg-color: rgba($brand-primary, 0.9);
$main-color: $brand-primary;
$anim-duration: .4s;
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
* {
  box-sizing: border-box;
}
.mobile{
  &-logo{
    max-height: 50px;
    width: auto;
  }
}
.outer-menu {
  position: fixed;
  top: 1vh;
  right: 0;
  z-index: 999999999999999999999999999999999999;
  .checkbox-toggle {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
    width: $hamburger-menu-size;
    height: $hamburger-menu-size;
    opacity: 0;
    &:checked {
      & + .hamburger {
        > div {
          background: white;
          transform: rotate(135deg);
          &:before,
          &:after {
            top: 0;
            transform: rotate(90deg);
          }
          &:after {
            opacity: 0;
          }
        }
      }
      & ~ .mobile-menu  {
        pointer-events: auto;
        visibility: visible;
        > div {
          transform: scale(1);
          transition-duration: .75s;
          > div {
            opacity: 1;
            transition: opacity $anim-duration ease $anim-duration;
          }
        }
      }
    }
    &:hover + .hamburger {
      box-shadow: inset 0 0 10px rgba(0, 0, 0, .1);
    }
    &:checked:hover + .hamburger > div {
      transform: rotate(225deg);
    }
  }
  .hamburger {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: $hamburger-menu-size;
    height: $hamburger-menu-size;
    padding: .5em 1em;
    //background: $hamburger-menu-bg-color;
    border-radius: 0 $hamburger-menu-border-radius $hamburger-menu-border-radius 0;
    cursor: pointer;
    transition: box-shadow $anim-duration ease;
    backface-visibility: hidden;
    @include flex-center;
    > div {
      position: relative;
      flex: none;
      width: 100%;
      height: $hamburger-menu-line-height;
      background: $main-color;
      transition: all $anim-duration ease;
      @include flex-center;
      &:before,
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        top: -$hamburger-menu-size / 6;
        right: 0;
        width: 100%;
        height: $hamburger-menu-line-height;
        background: inherit;
        transition: all $anim-duration ease;
      }
      &:after {
        top: $hamburger-menu-size / 6;
      }
    }
  }
  .mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    @include flex-center;
    > div {
      width: 250vw;
      height: 250vw;
      color: $main-color; 
      border-radius: 50%;
      transition: all $anim-duration ease;
      flex: none;
      transform: scale(0);
      backface-visibility: hidden;
      overflow: hidden;
      @include flex-center;
      > div {
        text-align: center;
        width: 100%;
        max-width: 250px;
        max-height: 100vh;
        opacity: 0;
        transition: opacity $anim-duration ease;
        overflow-y: auto;
        flex: none;
        @include flex-center;
        //> ul {
        //  list-style: none;
        //  padding: 0 1em;
        //  margin: 0;
        //  display: block;
        //  max-height: 100vh;
        //
        //  > li {
        //    padding: 0;
        //    margin: 1em;
        //    font-size: 24px;
        //    display: block;
        //
        //    > a {
        //      position: relative;
        //      display: inline;
        //      cursor: pointer;
        //      transition: color $anim-duration ease;
        //
        //      &:hover {
        //        color: $menu-text-color;
        //
        //        &:after {
        //          width: 100%;
        //        }
        //      }
        //
        //      &:after {
        //        content: '';
        //        position: absolute;
        //        z-index: 1;
        //        bottom: -.15em;
        //        right: 0;
        //        width: 0;
        //        height: $hover-line-height;
        //        background: $menu-text-color;
        //        transition: width $anim-duration ease;
        //      }
        //    }
        //  }
        //}
      }
    }
  }
}
.mobile-menu {
  &--exposed {
    background: white;
    color: $menu-text-color;
  }
  &--button {
    background: none;
    color: $menu-text-color;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999999;
    a {
      cursor: pointer;
    }
  }
  ul{
    width: 90%;
    padding: 0;
    margin: 0 auto;
    li{
      padding: 0;
      margin: 0;
      list-style: none;
      a{
        text-decoration: none;
        display: block;
        padding: 1em 1em 1em 1.2em;
        outline: none;
        //box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);
        color: $c-grey;
        //text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
        background: none;
        transition: background 0.3s, color 0.3s;

        text-align: left;
        font-family: "Open Sans";
        font-weight: 800;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 22px;
        &:hover{
          background: white;
          color: $menu-text-color;
        }
      }
    }
  }
  &--city{
    margin: 5px auto;
    text-align: center;
  }
}