.order-online-bar {
  overflow: hidden;
  margin-bottom: 100px;
  &__content-wrapper {
    text-align: center;
    padding-bottom: 34px;
    @include breakpoint(sm) {
      text-align: left;
    }
  }
  &__header {
    font-family: $font-family-sans-serif;
    font-weight: 800;
    color: white;
    margin: -5px 0 33px 0;
    text-align: center;
    line-height: 45px;
    font-size: 45px;
    @include breakpoint(sm) {
      font-size: 60px;
      line-height: 60px;
    }
    @include breakpoint(md) {
      font-size: 97px;
      line-height: 90px;
    }
  }
  &__lead {
    font-family: $font-family-sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: $c-grey;
    letter-spacing: 0.17px;
    line-height: 26px;
    margin: 0 0 30px 0;
    @include breakpoint(sm) {
      margin: 0 0 0 -15px;
    }
  }
  &__outside-bg {
    background: url('../Images/pattern.png');
  }
  &__inside-bg {
    margin: 0;
    @include breakpoint(md) {
      margin: 0 65px;
    }
    background: linear-gradient(41deg, #009BBA 3%, #23B9D7 100%);
  }
}
