.textpage {
  padding-top: 160px;
  padding-bottom: 160px;
  &__meta {
    h1 {
      a {
        font-size: 40px;
        @media screen and (min-width: $screen-lg) {
          font-size: 50px;
        }
        font-weight: 100;
        color: $brand-secondary;
        letter-spacing: 0.3px;
      }
      margin-top: 0;
      margin-bottom: 30px;
    }
    h3 {
      a {
        font-size: 16px;
        font-weight: 800;
        color: $brand-secondary;
        letter-spacing: 0.5px;
      }
      line-height: 22px;
      margin-top: 0;
    }
  }
  .neos-contentcollection {
    h1, h2, h3, h4 {
      color: $brand-primary;
      letter-spacing: 0.3px;
      line-height: 25px;
      margin-top: 0;
      margin-bottom: 30px;
    }
    h2 {
      font-size: 18px;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      color: $brand-secondary;
      letter-spacing: 0.17px;
      line-height: 26px;
    }
  }
}