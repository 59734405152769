/* typography */
html, body {
  font-family: $font-family-sans-serif;
  font-style: normal;
  font-weight: 100;
}

a {
  font-family: $font-family-sans-serif;
  font-weight: 400;
  color: $brand-primary;
  transition: 0.2s ease;
  &:hover {
    color: $brand-secondary;
    .fa {
      color: $brand-secondary;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-sans-serif;
}

h2 {
  font-size: 27px;
  @media screen and (min-width: $screen-sm) {
    font-size: 50px;
  }
}

/* container-fluid tricks */
@media screen and (min-width: $screen-lg) {
  .container-fluid {
    max-width: 2150px;
  }
}
.page-main {
  background: url('../Images/pattern.png');
  padding-top: 100px;
  @include breakpoint(sm) {
   background: none;
  }
  @include breakpoint(md) {
    padding-top: 0;
  }
}

.container-md-fullwidth {
  @media screen and (min-width: $screen-md-min) and (max-width: $screen-lg-min) {
    width: 100%;
  }
}

.ipad-portrait {
  .container-fluid {
    overflow: hidden;
  }
}

.container-fluid {
  –webkit-overflow-scrolling: touch;
  &--grey {
    background: #EDF0F2;
  }
}

/* remove focus/active chrome/safari blue borders */
textarea:hover,
input:hover:not([type="radio"]),
textarea:active,
input:active:not([type="radio"]),
textarea:focus,
input:focus:not([type="radio"]),
button:focus,
button:active,
button:hover {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

/* utility */
#map {
  height: 300px;
}

.no-visibility {
  visibility: hidden;
}


.form-group {
  .error-message {
    display: none;
  }
  margin-bottom: 35px;
  .error-message {

  }
  &--has-error {
    input, textarea {
      border: none;
    }
    .error-message {
      display: block;
      //background: white;
      padding: 5px 10px;
      color: $c-grey;

      font-size: 12px;
      position: absolute;
      border-radius: 5px;
      //font-weight: 600;
    }
  }
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-radius: 0;
}

//.pagination {
//  li {
//    &:first-child {
//      a {
//        font-size: 28px;
//        border-radius: 0;
//      }
//    }
//    &:last-child {
//      a {
//        font-size: 28px;
//        border-radius: 0;
//      }
//    }
//  }
//}

.artifical-height {
  padding-top: 160px;
}
.modal-lg {
  width: 90%!important;
}
.modal {
  .side-dish {
    img {
      width: 100%;
      height: auto;
      @include breakpoint(md) {
        max-width: 100%;
        height: auto;
      }
    }
  }
}