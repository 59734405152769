.formulary {
  background: $brand-primary;
  color: $brand-secondary;
  padding: 20px 10px;
  margin-bottom: 50px;
  @media screen and (min-width: $screen-sm) {
    padding: 10px 20px;
  }
  @media screen and (min-width: $screen-md) {
    padding: 60px 100px;
    .form {
      padding: 0 50px;
    }
  }
  .form{
    .inputs-list {
      li {
        float: left;
        width: 100%;
        @include breakpoint(xs) {
          width: 50%;
        }
        @include breakpoint(md) {
          width: 33%;
        }
      }
    }
    &__span {
      padding-left: 10px;
    }
    input[type=file] {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      opacity: 0;
      font-size: 200px;
      color: $c-grey;
      direction: ltr;
      cursor: pointer;
      display: block;
      height: 150px;
    }
    input[type="checkbox"] {
      position: relative;
      top: 1px;
      box-sizing: border-box;
      padding: 0 !important;
      -webkit-appearance: none;
      border-radius: 50% !important;
      z-index: 5;
      transition: 0.3s ease-in;
      height: 14px !important;
      width: 14px;
      margin: auto;
      border: 1px solid $c-grey !important;
      &:checked {
        background: $c-grey;
        &:after {
          content: '';
          padding: 11px;
          border: 1px solid $c-grey;
          position: absolute;
          top: -6px;
          left: -51%;
          color: #99a1a7;
          border-radius: 50%;
        }
      }
    }
    &__upload-wrapper {
      display: block;
      background: white;
      padding: 20px;
      overflow: hidden;
      position: relative;

      div {
        border: 2px dashed white;
        padding: 40px;
        font-family: $font-family-sans-serif;
        color: $c-grey;
        font-size: 1.2em;
        text-align: center;
      }
    }

    .upload {
      &__message {
        transition: 0.5s ease;
        &--alert {
          //color: $f-red;
        }
        &--hidden {
          display: none;
        }
      }
      &__file {
      }
    }
  }
  h3 {
    color: $brand-secondary;
    margin-top: 0;
    margin-bottom: 60px;
    text-align: center;


    font-family: $font-family-sans-serif;
    font-weight: 800;
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 25px;
  }
  fieldset {
    border: 0;
  }
  label {
    font-family: $font-family-sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: $c-grey;
    line-height: 22px;
  }
  legend {
    color: $brand-secondary;
  }
  textarea {
    min-height: 150px;
  }
  input, textarea {
    border-radius: 0;
    box-shadow: none;
    padding: 10px 5px 8px 13px;
    border: 3px solid white;
    transition: border 0.4s ease;
    height: 50px;
    &:focus {
      border: 3px solid $brand-secondary;
    }
  }
  label {
    font-weight: 400;
    color: $brand-secondary;
    margin-top: 15px;
  }
  .form-group{
    text-align: left;
  }
  .form-navigation {
    ul {
      list-style: none;
      text-align:right;
      padding-left: 0;
      li {
        .btn {
          display: inline-block;
        }
      }
    }
  }
  .next.submit {
    padding-right: 0;
  }

  .js .box__file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .js .box__file + label {
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
  }

  .js .box__file + label:hover strong,
  .box__file:focus + label strong,
  .box__file.has-focus + label strong {
    color: #39bfd3;
  }

  .js .box__file:focus + label,
  .js .box__file.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }

  .js .box__file + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
  }

  .no-js .box__file + label {
    display: none;
  }

  .no-js .box__button {
    display: block;
  }

}