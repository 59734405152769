.banner {
  position: relative;
  margin-bottom: 10px;
  background-position: 34%;
  @include breakpoint(sm) {
    background-position: auto;
  }
  @include breakpoint(md) {
    margin-bottom: 0;
  }
  &__header {
    font-family: $font-family-sans-serif;
    font-weight: 800;
    color: white;
    font-size: 30px;
    line-height: 90px;
    padding: 150px 0 150px 0;
    position: relative;
    z-index: 2;
    margin: 0;
    white-space: nowrap;
    @include breakpoint(sm) {
      padding: 200px 0 350px 0;
    }
    @include breakpoint(md) {
      font-size: 60px;
    }
    @include breakpoint(lg) {
      font-size: 97px;
    }
  }
  &__gradient {
    height: 180px;
    position: absolute;
    width: 100vw;
    right: 0;
    top: 102px;
    z-index: 1;
    @include breakpoint(sm) {
      height: 234px;
      top: 125px;
    }
    &--no-gradient {
      display: none;
    }
  }
}