.btn {
  font-size: 12px;
  letter-spacing: 0.1px;
  line-height: 11pt;
  font-family: $font-family-sans-serif;
  border-radius: 0;
  transition: 0.4s ease;
  padding: 18px 25px;
  font-weight: 800;
  text-transform: uppercase;

  color: #4B4A45 !important;
  background: $brand-primary;
  border: 1px solid $brand-primary;

  &-full {
    display: block !important;
  }
  &-primary {
    display: inline-block;
  }
  &-darktext {
    color: $brand-secondary;
  }
  &-secondary {
    display: inline-block;
    color: $c-grey;
    background: white;
    border: 1px solid $c-grey;
    &:hover, &:focus, &:active {
      //background: white;
      //border: 1px solid $brand-secondary;
      //color: $brand-secondary;
    }
  }
  &-transparent {
    color: $brand-primary;
    background: transparent;
    border: 1px solid $brand-primary;
    &:hover, &:focus, &:active {
      background: transparent;
      border: 1px solid $brand-primary;
      color: $brand-secondary;
    }
  }
  &-dark {
    color: $brand-primary !important;
    background: $brand-secondary;
    border: 1px solid $brand-secondary;
    &:hover {
      color: $brand-secondary !important;
      background: $brand-primary;
      border: 1px solid $brand-secondary;
    }
  }
  &:hover, &:focus, &:active {
    color: $brand-primary;
    background-color: white;
    border: 1px solid $brand-secondary;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.15);
  }
  &--active {
    color: white;
    background-color: $brand-secondary;
    border: 1px solid $brand-secondary;
    &:hover {
      color: $brand-secondary;
      background-color: white;
    }
  }
  &-sm {
    font-size: 8pt !important;
    padding: 13px 20px 13px;
    border-radius: 7px;
  }
  &-wide {
    margin-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
  }
}



