.slick-full-width-dave {
  text-align: center;
  margin-bottom: 100px;
  .slick-dots {
    margin-top: 40px;
  }
  &__margin-top-100 {
    margin-top: 100px;
  }
  &__background {
    background: url('../Images/pattern.png');
    .slick__header {
      padding-top: 0;
    }
  }
  &--black-hover {
    .slick {
      &-next, &-prev {
        background: transparent;
        &:hover {
          background: rgba(black, 0.5);
        }
      }
      &-next {
        background: rgba(black, 0.3);
      }
    }
  }
  &__artifical-height {
    height: 350px;
  }
  &__slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    height: 300px;
    @include breakpoint(xs) {
      height: 400px;
    }
    @include breakpoint(sm) {
      display: block;
      padding: 120px 0 120px 0;
      min-height: 600px;
    }
    &-front {
      padding: 0 10px;
      width: 100%;
      @include breakpoint(sm) {
        padding: 0;
      }
    }
    &-back {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      //img {
      //  height: 100%;
      //  width: auto;
      //  @include breakpoint(lg) {
      //    width: 100%;
      //    height: auto;
      //  }
      //
      //}
      &--mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #6B6B6B;
        mix-blend-mode: multiply;
      }
    }
    &-header {
      font-family: $font-family-sans-serif;
      font-weight: 800;
      font-size: 34px;
      line-height: 45px;
      @include breakpoint(sm) {
        margin: 0 0 50px 0;
      }
    }
    &-lead {
      font-family: $font-family-sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: white;
      line-height: 22px;
      margin-bottom: 45px;
      display: none;
      @include breakpoint(sm) {
        display: block;
      }
    }
  }
  .slick {
    &-next, &-prev {
      width: 14%;
      height: 100%;
      background: transparent;
      transition: .4s ease-in;
      &:before {
        font-size: 125px;
        transition: .4s ease-in;
      }
      &:hover {
        background: rgba(white, 0.5);
      }
    }
    &-next {
      right: 0;
      background: rgba(white, 0.3);
    }
    &-prev {
      left: 0;
    }
    &-disabled {
      background: transparent;
      &:before {
        opacity: 0.75;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
  }
}


.slick-full-width-meals-dave {
  text-align: center;
  &__background {
    background: url('../Images/pattern.png');
  }
  &__image {
    z-index: 1000;
    transition: .3s;
  }
  &__artifical-height {
    padding-top: 50px;
  }
  &__button-wrapper {
    padding: 50px 0 80px 0;
  }
  &__desc {
    padding-top: 80px;
    &-name {
      font-family: 'Open Sans';
      font-weight: 800;
      font-size: 16px;
      color: #4B4A45;
      line-height: 22px;
      letter-spacing: 0.5px;
      margin: 0 0 1px;
    }
    &-price {
      font-family: 'Open Sans';
      font-weight: 800;
      font-size: 18px;
      line-height: 26px;
      margin: 0 0 1px;
    }
    &-ingredient {
      font-family: $font-family-sans-serif;
      font-family: 400;
      color: #9B9B9B;
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 1px;
    }
  }
  &--gallery-option {
    .slick {
      &-next, &-prev {
        &:before {
          top: 0 !important;
        }
      }
    }
  }
  .slick {
    &-track {
      margin: 40px 0;
    }
    &-slide {
      .slick-full-width-meals-dave {
        &__desc {
          opacity: 0;
        }
      }
    }
    &-center {
      .slick-full-width-meals-dave {
        &__desc {
          @include fadeInDown();
          opacity: 1;
        }
        &__image {
          transform: scale(1.3);
        }
      }
    }
    &-next, &-prev {
      width: 14%;
      height: 100%;
      transition: .4s ease-in;
      background: rgba(white, 0.5);
      &:before {
        font-size: 125px;
        transition: .4s ease-in;
        position: relative;
        top: -76px;
      }
      &:hover {
        background: rgba(white, 0.8);
      }
    }
    &-next {
      right: 0;
    }
    &-prev {
      left: 0;
    }
    &-disabled {
      background: transparent;
      &:before {
        opacity: 0.75;
      }
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.slick {
  &-dots {
    @include breakpoint(sm) {
      margin-top: 40px;
    }
  }
  &__header {
    font-family: $font-family-sans-serif;
    font-weight: 100;
    font-size: 30px;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 10px;
    line-height: 66px;
    letter-spacing: 0.31px;
    color: #4B4A45;
    @include breakpoint(sm) {
      font-size: 50px;
    }
  }
  &__lead {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.3px;
    margin-bottom: 50px;
  }
}
.single-slick-news {
  background: url('../Images/pattern.png');
  &__left-block {
    margin: 0 -15px;
    text-align: right;
    max-height: 615px;
    padding: 0 15px 60px 15px;
    @include breakpoint(sm) {
      padding: 60px 100px 60px 120px;
    }
  }
  &__right-block {
    overflow: hidden;
    max-height: 250px;
    @include breakpoint(sm) {
      max-height: 615px;
    }
    img {
      height: auto;
      width: 100%;
      @include breakpoint(sm) {
        //height: 100%;
        display: block;
      }
    }
  }
}