.information-bar {
  text-align: center;
  overflow: hidden;
  margin: 100px 0;
  &__header {
    @extend .order-online-bar__header;
  }
  &__lead {
    @extend .order-online-bar__lead;
    text-align: center;
    @include breakpoint(sm) {
      text-align: right;
    }
  }
  &__wrapper {
    margin: 0 10px;
  }
  &__content-wrapper {
    padding-bottom: 34px;
  }
  &--centering {
    display: flex;
    align-items: center;
    flex-flow: column;
    @include breakpoint(sm) {
      flex-flow: row;
    }
  }
  &__button-wrapper {
    text-align: center;
    @include breakpoint(sm) {
      text-align: left;
    }
  }
}