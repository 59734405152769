.side-dish {
  &__close {
    float: right;
    margin: 17px 15px 0 0;
  }
  &__header {
    font-family: $font-family-sans-serif;
    font-weight: 100;
    color: $c-grey;
    font-size: 50px;
    line-height: 66px;
    letter-spacing: 0.31px;
    margin: 42px 0 11px 0;
  }
  &__subheader {
    font-family: $font-family-sans-serif;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.3px;
    margin: 0 0 45px 0;
  }
  &__main {
    font-family: "Open Sans";
    font-weight: 800;
    font-size: 16px;
    color: $c-grey;
    letter-spacing: 0.5px;
    line-height: 22px;
    margin: 48px 0 14px 0;
  }
  &__additional {
    line-height: 45px;
    margin-bottom: 10px;
  }
  &__choice {
    font-family: $font-family-sans-serif;
    font-weight: 400;
    color: $c-grey;
    font-size: 18px;
    letter-spacing: 0.17px;
    line-height: 26px;
    margin: 0 0 26px 0;
    &-actions {
      margin: 0 0 30px 0;
      .btn {
        &:first-child {
          margin-right: 30px;
        }
      }
    }
    &-total {
      border-top: 1px solid #979797;
      margin: 27px 0 30px 0;
      padding: 15px 0 0 0;
      &-block {
        padding-right: 30px;
        .side-dish__choice-list {
          font-family: "Open Sans";
          font-weight: 800;
          letter-spacing: 0.5px;
          font-size: 16px;
          line-height: 22px;
          margin: 0 0 10px 0;
        }
      }
    }
  }
  &__price {
    font-family: $font-family-sans-serif;
    font-weight: 800;
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 25px;
  }
  &__choice-header {
    font-family: $font-family-sans-serif;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.3px;
    padding: 8px 0 7px 26px;
    color: white;
    margin: 0 -15px 28px 0;
    &--last {
      margin: 0 -45px 35px 0;
      padding: 8px 45px 7px 26px;
    }
  }
  &__choice-list {
    ul {
      @extend .clearfix;
      li {
        @media (min-width: $screen-sm) {

          float: right;
          width: 50%;
        }
          min-height: 50px;
          position: relative;
      }
    }
    &-subchoice {
      &-header {
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 22px;
      }
      span {
        font-family: $font-family-sans-serif;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: 0.17px;
        line-height: 26px;
        text-transform: lowercase;
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
    &-link {
      padding-left: 25px;
      color: $c-grey;
      &:hover {
        text-transform: none;
      }
    }
    .check-btn {
      position: absolute;
      top: 5px;
      left: 0;
      &__small {
        display: block;
        border-radius: 100%;
        z-index: 5;
        transition: border .25s linear;
        height: 13px;
        width: 13px;
        margin: auto;
      }
      &__large {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.5s ease;
        position: absolute;
        border-radius: 100%;
        content: '';
        height: 21px;
        width: 21px;
        top: -4px;
        left: -4px;
      }
      &--active {
        .check-btn__small {

        }
        .check-btn__large {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .check-btn--active {
      .check-btn {
        border: 1px solid transparent;
      }
    }
  }
}