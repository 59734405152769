.feats {
  padding-bottom: 100px;
  .slick {
    &__header, &__lead {
      text-align: center;
    }
  }
  .btn {
    margin-top: 40px;
  }
  &__button-wrapper {
    text-align: center;
  }
}