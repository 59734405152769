/** slick initialize css **/
/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-nav {
  img {
    padding: 10px;
  }
}

.bottom-slick {
  &__header {
    margin-top: 80px;
    margin-bottom: 60px;
    color: $brand-secondary;
  }
  .slick-slide {
    padding: 0 20px;
  }
  .slick-dots {
    margin-top: 30px;
    margin-bottom: 140px;
  }
}

/** slick customize css **/
@charset "UTF-8";

// Default Variables

$slick-font-path: "../fonts/";
$slick-font-family: "slick";
$slick-loader-path: "./";
$slick-arrow-color: white;
$slick-dot-color: black;
$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: "←";
$slick-next-character: "→";
$slick-dot-character: "•";
$slick-dot-size: 6px;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;

/* Slider */
.slick-loading .slick-list {
  background: #fff url('#{$slick-loader-path}ajax-loader.gif') center center no-repeat;
}

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;

  src: url('#{$slick-font-path}slick.eot');
  src: url('#{$slick-font-path}slick.eot?#iefix') format('embedded-opentype'), url('#{$slick-font-path}slick.woff') format('woff'), url('#{$slick-font-path}slick.ttf') format('truetype'), url('#{$slick-font-path}slick.svg#slick') format('svg');
}

/* Arrows */

.slick-prev,
.slick-next {
  z-index: 9999;
  position: absolute;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
}

.slick-prev:before, .slick-next:before {
  font-family: $slick-font-family;
  font-size: 20px;
  line-height: 1;
  color: $slick-arrow-color;
  opacity: $slick-opacity-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 25px;
  &[dir="rtl"] {
    left: auto;
    right: -25px;
  }
  &:before {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f104";
    font-size: 50px;
    color: $brand-primary;
    &[dir="rtl"] {
      content: "\f105";
    }
  }
}

.slick-next {
  right: 25px;
  &[dir="rtl"] {
    left: -25px;
    right: auto;
  }
  &:before {
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f105";
    font-size: 50px;
    color: $brand-primary;
    &[dir="rtl"] {
      content: "\f104";
    }
  }
}

/* Dots */

.slick-dotted .slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      display: block;

      height: 10px;
      width: 10px;

      border: 1px solid #C2C2C2;
      border-radius: 10px;
      background: transparent;

      outline: none;

      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        border: 1px solid $brand-secondary;
        background: $brand-primary;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

.slick-dots {
  .slick-active {
    button {
      background: $brand-primary;
    }
  }
}

.slick-slide {
  img {
    margin: 0 auto;
  }
}