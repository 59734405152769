
// =============================================================================
// DevHero Mixins
// =============================================================================

@mixin transition ($speed: 0.4s, $type: ease-in) {
  transition: $speed, $type;
}


// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// =============================================================================
// Font Face
// https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
// @include font-face(Samplino, fonts/Samplino);
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
          eot: "?",
          svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
          otf: "opentype",
          ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin media($point) {
  @if $point == "max-479" {
    @media (max-width: 479px) {
      @content;
    }
  }
  @else if $point == "min-480" {
    @media (min-width: 480px) {
      @content;
    }
  }
  @else if $point == "max-599" {
    @media (max-width: 599px) {
      @content;
    }
  }
  @else if $point == "min-600" {
    @media (min-width: 600px) {
      @content;
    }
  }
  @else if $point == "max-767" {
    @media (max-width: 767px) {
      @content;
    }
  }
  @else if $point == "min-768" {
    @media (min-width: 768px) {
      @content;
    }
  }
  @else if $point == "max-991" {
    @media (max-width: 991px) {
      @content;
    }
  }
  @else if $point == "min-992" {
    @media (min-width: 992px) {
      @content;
    }
  }
  @else if $point == "min-1200" {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @else if $point == "min-1350" {
    @media (min-width: 1350px) {
      @content;
    }
  }
  @else if $point == "min-1550" {
    @media (min-width: 1550px) {
      @content;
    }
  }
}

@mixin breakpoint($class) {
  @if $class == xs {
    @media (min-width: 480px) { @content; }
  }
 
  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }
 
  @else if $class == md {
    @media (min-width: 992px) { @content; }
  }
 
  @else if $class == lg {
    @media (min-width: 1300px) { @content; }
  }
}