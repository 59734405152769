.transition {
  margin-bottom: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
  @include breakpoint(sm) {
    max-height: 150px;
    padding-top: 0;
    padding-bottom: 0;
  }
  &__wrapper {
    position: relative;
  }
  &__button {
    text-align: center;
    line-height: auto;
    margin: 30px 0 0 0;
    padding-bottom: 50px;
    @include breakpoint(sm) {
      line-height: 150px;
      text-align: left;
      margin: 0 0 0 0;
      padding-bottom: 0;
    }
  }
  &__header {
    font-family: "Open Sans";
    font-weight: 800;
    color: white;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.5px;
    margin: 0 3px 0 0;
    text-transform: uppercase;
  }
  &__lead {
    margin: 0;
    font-family: $font-family-sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.17px;
  }
  &__image {
    text-align: center;
    position: static;
    @include breakpoint(sm) {
      position: relative;
      top: -65px;
    }
    &-wrapper {
      position: absolute;
      top: 70px;
      right: 0;
      left: 0;
      transform: scale(0.8);
      @include breakpoint(sm) {
        position: static;
      }
    }
  }
  &__content-wrapper {
    text-align: center;
    @include breakpoint(sm) {
      padding-top: 30px;
      text-align: left;
    }
    @include breakpoint(md) {
      padding-top: 50px;
      text-align: left;
    }
  }
}