.lead {
  margin: 10px 0 0 0;
  @include breakpoint(sm) {
    margin: 10px 15px 0 15px;
  }
  @include breakpoint(md) {
    margin: -150px 0 0 8.33%;
  }
  &__bg {
    background: url('../Images/pattern.png');
    padding-bottom: 100px;
  }
  &--white-bg {
    background: url('../Images/pattern.png');
    position: relative;
    overflow: hidden;
    @include breakpoint(md) {
      background: white;
    }
  }
  &__bg-wrapper {
    position: absolute;
    top: 0;
    left: -1px;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }
  &__color-red {
    color: #D0011B;
    &-not-available {
      color: #D0011B;
    }
  }
  &__color-green {
    color: green;
  }
  &__left-block {
    padding: 30px 0 70px 0;
    @include breakpoint(md) {
      padding: 100px 15px 90px 75px;
    }
  }
  &__right-block {
     padding-bottom: 48px;
    @include breakpoint(md) {
      padding-bottom: 0;
    }
  }
  &__header {
    font-family: "Poppins";
    font-weight: 100;
    color: $c-grey;
    font-size: 50px;
    line-height: 66px;
    letter-spacing: 0.31px;
    margin: 0 0 50px 0;
  }
  &__sub-header {
    font-family: "Poppins";
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.3;
    margin: 0 0 30px 0;
  }
  &__lead {
    font-family: "Poppins";
    line-height: 26px;
    font-weight: 400;
    color: $c-grey;
    font-size: 18px;
    margin: 0 0 30px 0;
  }
  &__buttons {
    text-align: center;
    @include breakpoint(sm) {
      text-align: right;
    }
    .btn:first-child {
      @include breakpoint(xs) {
        margin-right: 10px;
      }
      @include breakpoint(sm) {
        margin-right: 30px;
      }
    }
    .btn {
      display: block;
      margin-top: 15px;
      @include breakpoint(xs) {
        display: inline-block;
        margin-top: 0;
      }
    }
  }
  &__contact {
    text-align: center;
    ul {
      margin: 0;
      padding: 60px 0 40px 0;
      li {
        padding-bottom: 50px;
        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  &__contact-item {
    font-family: $font-family-sans-serif;
    font-weight: 400;
    color: $c-grey;
    font-size: 14px;
    display: inline-block;
    width: 65%;
    padding: 0 0 0 30px;
    text-align: left;
    @include breakpoint(xs) {
      width: 50%;
    }
  }
  &__side-bar-numbers {
    padding: 100px 0 0 27px;
    @include breakpoint(md) {
      padding: 100px 0 0 85px;
    }
    .lead {
      &__header {
        margin: 0 0 0 0;
      }
      &__lead {
        margin: 0 0 24px 0;
      }
    }
  }
  &__extend-top-one {
    margin: 0 -15px;
    text-align: center;
  }
  &__open-hours {
    @extend .lead__lead;
    color: $c-grey;
    margin: 0;
    padding: 34px 0 14px 0;
  }
  &__white-bar {
    font-family: $font-family-sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    background: white;
    display: inline-block;
    margin: 0 auto;
    padding: 3px 9px;
    margin: 0 15px;
  }
  &__open-info {
    ul {
      margin: 0;
      padding: 20px 0;
      li {
        font-family: $font-family-sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $c-grey;
      }
    }
    &--alt-padding {
      ul {
        padding: 5px 0 30px 0;
      }
    }
    &--json {
      display: none;
    }
  }
  &__no-info {
    &-header {
      font-family: $font-family-sans-serif;
      color: $c-grey;
      font-size: 18px;
      font-weight: 800;
      line-height: 25px;
      letter-spacing: 0.3px;
      margin: 0;
      padding: 35px 0 5px 0;
      &--smallPadding {
        padding: 25px 0 0 0;
      }
    }
    &-sub-header {
      margin: 0;
      font-family: $font-family-sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
  &__search {
    margin: 0 -15px;
    padding-top: 30px;
    &-bar {
      padding: 45px 35px 60px 35px;
      .form-control {
        box-shadow: none;
        height: 50px;
        border: 1px solid white;
        border-radius: 0;
      }
      .btn {
        background: $c-grey;
        padding: 0;
        border: none;
        border-radius: 0;
        width: 50px;
        height: 50px;
        i {
          font-size: 20px;
          margin: 0;
          padding: 14.5px 16.475px;
          transition: .3s;
          position: relative;
          top: -2px;
        }
        &:hover {
          i {
            color: white;
          }
        }
      }
    }
    &-desc {
      margin: 0;
      padding: 0 0 30px 0;
      text-align: center;
      color: white
    }
    &-header {
      font-family: $font-family-sans-serif;
      font-weight: 800;
      font-size: 18px;
      margin: 0 0 6px 0;
      line-height: 25px;
      letter-spacing: 0.3px;
    }
    &-sub-header {
      font-family: $font-family-sans-serif;
      font-weight: 400;
      font-size: 14px;
    }
  }
  &__sidebar{
    &-info {
      margin: 0 -15px;
      text-align: center;
      img {
        padding: 55px 0;
      }
      &-header {
        font-family: $font-family-sans-serif;
        font-weight: 800;
        font-size: 24px;
        color: $c-grey;
        line-height: 40px;
        margin: 0;
      }
      &-sub-header {
        font-family: $font-family-sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: $c-grey;
        margin: 0;
        &:first-of-type {
          padding: 7px 0 20px 0;
        }
        &:last-of-type {
          padding: 60px 60px 0 60px;
        }
      }
    }
  }
}