.user-content {
    p {
        font-family: $font-family-sans-serif;
        line-height: 26px;
        font-weight: 400;
        color: $c-grey;
        font-size: 18px;
        margin: 0 0 30px 0;
    }
    blockquote {
        font-weight: 200;
        font-size: 30px;
        color: $c-red;
        text-align: left;
        border-left: 1px solid $c-red;
        padding-left: 40px;
    }
}

.neos-nodetypes-text {
    ul {
        li {
            line-height: 52px;
        }
    }
    p {
        font-family: $font-family-sans-serif;
        line-height: 26px;
        font-weight: 100;
        color: $c-grey;
        font-size: 18px;
        margin: 0 0 30px 0;
    }
    blockquote {
        font-weight: 200;
        font-size: 30px;
        color: $c-red;
        text-align: left;
        border-left: 1px solid $c-red;
        padding-left: 40px;
    }
    h2 {
        font-family: $font-family-sans-serif;
        font-weight: 100;
        color: $c-grey;
        font-size: 35px;
        line-height: 66px;
        letter-spacing: 0.31px;
        margin: 0 0 50px 0;
        @include breakpoint(xs) {
            font-size: 50px;
        }
    }
    h3 {
        font-family: $font-family-sans-serif;
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.3;
        margin: 0 0 30px 0;
    }
}

.about-description {
    padding-top: 50px;
    padding-bottom: 50px;
}