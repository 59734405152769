.head {
  transition: 0.3s;
  &-scrolled {
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1049;
    @extend .page__border--bottom;
    .head__menu {
      li {
        line-height: 100px;
      }
    }
    .head__logo {
      margin: 0 auto;
      padding: 0;
      line-height: 100px;
      img {
        display: inline-block;
      }
    }
    .head__menu-item {
      padding: 39px 11px;
    }
    .col-sm-3 {
      @extend .col-sm-2;
    }
  }
  &__logo {
    display: inline-block;
    line-height: 160px;
    img {
      display: inline-block;
      line-height: 160px;
    }
  }
  &__menu {
    margin: 0;
    li {
      display: inline-block;
      position: relative;
      line-height: 160px;
      &:hover {
        .head__triangle {
          opacity: 1;
        }
      }
    }
    &-item {
      padding: 69px 11px;
      line-height: 22px;
      display: inline-block;
      color: $c-grey;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__triangle {
    transition: 0.3s ease;
    position: absolute;
    top: 0;
    left: calc(50% - 15px);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid white;
    opacity: 0;
  }
  &-mobile {
    position: fixed;
    left: 0;
    right: 0;
    background: white;
    z-index: 999999999;
    .floating-logo {
      padding: 18px 0 26px 0;
    }
    .outer-menu {
      //top: 45px;
      //right: 5%;
      .hamburger > div {
        height: 3px;
        background: $c-grey;
        border-radius: 25px;
        &:before, &:after {
          height: 3px;
          border-radius: 25px;
        }
      }
    }
  }
}

