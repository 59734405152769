@import 'Base/mixins';
@import 'Base/variables';
@import 'Base/fonts';

@import "Vendors/fontawesome/_font-awesome";
@import "Vendors/animation/animate";

@import 'Base/bootstrap-custom';
@import 'Base/global';

@import 'Components/index';

@import 'Layout/index';
:focus {
  outline: none;
}

  .js-control {
    display: none;
  }

.devhero__alert {
  padding-top: 160px;
  @include breakpoint(md) {
    padding-top: 0;
  }
}

