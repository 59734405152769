.blogpage {
  margin-top: 50px;
  margin-bottom: 100px;
  &-sidebar {
    h3 {
      font-size: 18px;
      color: $brand-primary;
      letter-spacing: 0.3px;
      line-height: 25px;
      margin-bottom: 28px;
      margin-top: 65px;
      &:first-child {
        margin-top: 0;
      }
    }
    .fa {
      font-size: 26px;
    }
  }
}
