.offer {
    &__header {
        padding: 50px 30px;
        text-align: center;
        font-weight: normal;
    }
    &__subheader {
        color: $c-yellow-dark;
        font-weight: normal;
    }
}