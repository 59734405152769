.article {
  * {
    transition: .5s ease-in-out;
  }
  background-size: cover !important;
  overflow: hidden;
  height: 370px;
  padding: 30px 30px 35px 30px;
  text-align: center;
  margin-bottom: 100px;
  position: relative;
  &:hover {
    .article__back {
      opacity: 1;
    }
    .article__publication-date, .article__title,{
      opacity: 0;
    }
    .article__opacity {
      opacity: 0.5;
    }
  }
  &__tag {
    position: relative;
    z-index: 1000;
    display: inline-block;
    padding: 6px 16px 7px 17px;
    color: $c-grey;
    background: #EBC165;
    font-family: $font-family-sans-serif;
    font-weight: 800;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 1px;
    float: left;
  }
  &__publication-date {
    position: relative;
    z-index: 1000;
    display: inline-block;
    float: right;
    font-family: $font-family-sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #EBC165;
    background: $c-grey;
    line-height: 22px;
    padding: 7px 14px 6px 16px;
  }
  &__back {
    opacity: 0;
  }
  &__opacity {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black, 0.5);
  }
  &__content {
    font-family: "Open Sans";
    font-weight: 800;
    color: white;
    font-size: 18px;
    line-height: 26px;
    padding: 0 20%;
    &-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
    }
  }
  &__button-wrapper {
    position: absolute;
    text-align: center;
    bottom: 0;
    right: 0;
    left: 0;
  }
  &__title {
    font-family: $font-family-sans-serif;
    font-weight: 800;
    line-height: 45px;
    color: white;
    font-size: 34px;
    position: absolute;
    bottom: 35px;
    left: 0;
    right: 0;
  }
}